import Vue from 'vue'
import Vuex from 'vuex'
import kmAPI from '@/api/knowledgeMap'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    chosenProject: null,
    showTutorial: true,
    tutorialText: '',
    tmitocarOnline: false,
    spotlightOnline: false,
    hidePageElements: false,
    kowledgeMapOptions: []
  },
  mutations: {
    setChosenProject (state, project = null) {
      state.chosenProject = project
    },
    toogleTutorialState (state) {
      state.showTutorial = !state.showTutorial
      localStorage.setItem('showTutorial', state.showTutorial)
    },
    setTutorialText (state, text) {
      state.tutorialText = text
    },
    setServicesOnlineStatus (state, onlineStatus) {
      state.tmitocarOnline = onlineStatus.tmitocarOnline
      state.spotlightOnline = onlineStatus.spotlightOnline
    },
    setKowledgeMapOptions (state, options) {
      state.kowledgeMapOptions = options
    },
    setHidePageElements (state, hidePageElements) {
      state.hidePageElements = hidePageElements
    }
  },
  actions: {
    async fillKowledgeMapOptions () {
      const kmaps = await kmAPI.getAvailableMaps()
      const options = kmaps.map((kmap) => { return { value: kmap['@id'], text: kmap.label } })
      this.commit('setKowledgeMapOptions', options)
    }
  }
})
