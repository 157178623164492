import Vue from 'vue'
import { VuePlugin } from 'vuera'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import BootstrapVue from 'bootstrap-vue' // See https://bootstrap-vue.js.org/docs/ for bundle optimization
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core' // See https://www.npmjs.com/package/@fortawesome/vue-fontawesome#recommended for bundle optimization
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import AsyncComputed from 'vue-async-computed'
import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
import { required, min, integer, min_value, ext, mimes, size} from 'vee-validate/dist/rules' // eslint-disable-line
import isURL from 'validator/lib/isURL'

Vue.config.productionTip = false

Vue.use(VuePlugin)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(AsyncComputed)

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

localize('de', de)

extend('required', required)
extend('integer', integer)
extend('min_value', min_value)
extend('min', min)
extend('ext', ext)
extend('mimes', mimes)
extend('size', size)
extend('url', {
  validate: value => {
    return isURL(value, { protocols: ['http', 'https'], require_protocol: true, require_valid_protocol: true })
  },
  message: '{_field_} muss eine gültige URL sein'
})

new Vue({
  router,
  store,
  render: h => h(App),
  async created () {
    const showTutorial = localStorage.getItem('showTutorial')
    if ((showTutorial === 'true') !== this.$store.state.showTutorial && showTutorial !== null)
      this.$store.commit('toogleTutorialState')
  }
}).$mount('#app')
