const isEmpty = require('lodash/isEmpty')
const N3 = require('n3')

module.exports = {
  loadFileContent: async function (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async function (e) {
        resolve(e.target.result)
      }
      reader.readAsBinaryString(file)
    })
  },

  parseTurtle: async function (turtleDoc) {
    const parser = new N3.Parser({ format: 'Turtle' })
    const [quads] = await new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      const quads = []
      parser.parse(turtleDoc, (error, quad, prefixes) => {
        if (error)
          reject(error)
        else if (quad)
          quads.push(quad)
        else
          resolve([quads, prefixes])
      })
    })
    return quads
  },

  writeRDF: async function (quads, format = 'application/n-triples') {
    return new Promise((resolve, reject) => {
      const options = { }
      if (format) options.format = format
      const writer = new N3.Writer(options)
      quads.forEach((quad) => {
        writer.addQuad(quad)
      })
      writer.end((error, result) => {
        if (error !== null)
          reject(error)
        resolve(result)
      })
    })
  },

  timeout: async function (timeInMS) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, timeInMS)
    })
  },

  convertTimeFormatToHTML: function (timeString) { // 01P02T27M00S
    if (!isEmpty(timeString)) {
      let timeArray = timeString.split(/P|T|M|S/i).slice(1, 4) // [hours,mins,secs]
      timeArray = timeArray.map(el => {
        if (el.length === 1)
          return '0' + el
        if (el.length === 2)
          return el
        else
          return '00'
      })
      return timeArray[0] + ':' + timeArray[1] + ':' + timeArray[2]
    } else
      return ''
  },

  convertTimeFormatToPTMS: function (timeString) { // 00:00:00 (H:M:S)
    if (!isEmpty(timeString)) {
      const timeArray = timeString.split(':')// [hours,mins,secs]
      return '00' + 'P' + timeArray[0] + 'T' + timeArray[1] + 'M' + timeArray[2] + 'S'
    } else
      return undefined
  },

  decodeUmlauts (uri) {
    let alteredUri = uri.replace('%C3%A4', 'ä')
    alteredUri = alteredUri.replace('%C3%84', 'Ä')
    alteredUri = alteredUri.replace('%C3%BC', 'ü')
    alteredUri = alteredUri.replace('%C3%9C', 'Ü')
    alteredUri = alteredUri.replace('%C3%B6', 'ö')
    alteredUri = alteredUri.replace('%C3%96', 'Ö')
    alteredUri = alteredUri.replace('%C3%9F', 'ß')
    return alteredUri
  },

  isTextFile (file) {
    return file.name.endsWith('.txt') && (file.type === 'text/plain' || file.type === '')
  },

  isPDFFile (file) {
    return file.name.endsWith('.pdf') && (file.type === 'application/pdf' || file.type === '')
  }

}
