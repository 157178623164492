import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store/index'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: (_, __, next) => {
        getThirdPartyServiceStatus(true, true)
        next()
      }
    },
    {
      path: '/tmitocar',
      name: 'tmitocar',
      component: () => import(/* webpackChunkName: "tmitocar" */ '@/views/TMitocar.vue'),
      beforeEnter: (_, __, next) => {
        getThirdPartyServiceStatus(true, false)
        next()
      }
    },
    {
      path: '/dskgcreation',
      name: 'dskgcreation',
      component: () => import(/* webpackChunkName: "tmitocar" */ '@/views/DSKGCreation.vue'),
      beforeEnter: (_, __, next) => {
        getThirdPartyServiceStatus(false, true)
        next()
      },
      props: true
    },
    {
      path: '/ontodia',
      name: 'ontodiaRDFStore',
      component: () => import(/* webpackChunkName: "ontodia" */ '@/views/OntodiaStore.vue'),
      props: true
    },
    {
      path: '/ontodiaLocalFile',
      name: 'ontodiaLocalFile',
      component: () => import(/* webpackChunkName: "ontodia" */ '@/views/OntodiaLocal.vue')
    },
    {
      path: '/3dgraph',
      name: '3dgraph',
      component: () => import(/* webpackChunkName: "ontodia" */ '@/views/forceGraph.vue'),
      props: true
    },
    {
      path: '/sigma',
      name: 'sigma',
      component: () => import(/* webpackChunkName: "ontodia" */ '@/views/sigmaCompare.vue'),
      props: true
    },
    // {
    //   path: '/linking',
    //   name: 'linking',
    //   component: () => import(/* webpackChunkName: "ontodia" */ '@/views/Ontodia.vue'),
    //   props: { dynamicLinking: true }
    // },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    }
  ]
})

async function getThirdPartyServiceStatus (tmitocar = false, spotlight = false) {
  if (!store.state.tmitocarOnline && tmitocar) {
    const tmitocarOnline = await isServiceOnline('https://tech4comp.paedagogik.uni-halle.de/BMBF_RGB_Gef_S.jpg')
    store.commit('setServicesOnlineStatus', { tmitocarOnline, spotlightOnline: store.state.spotlightOnline })
  }
  if (!store.state.spotlightOnline && spotlight) {
    const spotlightOnline = await isServiceOnline('https://www.dbpedia-spotlight.org/favicon.ico')
    store.commit('setServicesOnlineStatus', { tmitocarOnline: store.state.tmitocarOnline, spotlightOnline })
  }
}

async function isServiceOnline (pictureURL) {
  const result = await new Promise((resolve, reject) => {
    const img = document.body.appendChild(document.createElement('img'))
    img.id = 'onlineTest'
    img.onload = () => resolve(true)
    img.onerror = () => reject(false) //eslint-disable-line
    img.src = pictureURL + '?' + Date.now()
  })
  document.getElementById('onlineTest').remove()
  return result
}
