<template>
  <div>

    <b-card-group deck>
      <b-card :class="($store.state.tmitocarOnline) ? 'selectable' : 'customDisabled'" @click="$router.push({ name: 'tmitocar' })">
        <div slot="header">
          <h4 class="float-left">Wissenslandkarte erzeugen</h4>
          <h4 class="float-right"><font-awesome-icon icon="project-diagram"/></h4>
        </div>
        <b-card-text>
          Konvertieren Sie Texte bzw. Dateien zu Wissenslandkarten (T-Mitocar) und entscheiden Sie sich, ob Sie diese als Fachlandkarte online speichern wollen
        </b-card-text>
        <b-badge :variant="($store.state.tmitocarOnline) ? 'success' : 'danger'"><font-awesome-icon icon="globe-europe"/> T-Mitocar ist {{($store.state.tmitocarOnline) ? 'online' : 'offline'}}</b-badge>
      </b-card>

      <b-card :class="($store.state.spotlightOnline) ? 'selectable' : 'customDisabled'" @click="$router.push({ name: 'dskgcreation' })">
        <div slot="header">
          <h4 class="float-left">Fachlandkarte erzeugen</h4>
          <h4 class="float-right"><font-awesome-icon icon="code-branch"/></h4>
        </div>
        <b-card-text>
          Erzeugen Sie Fachlandkarten aus Wissenslandkarten (T-Mitocar), mit der Option zur automatisierten Annotation durch DBpedia Spotlight. Fachlandkarten werden online gespeichert
        </b-card-text>
        <b-badge :variant="($store.state.spotlightOnline) ? 'success' : 'danger'"><font-awesome-icon icon="globe-europe"/> DBpedia Spotlight ist {{($store.state.spotlightOnline) ? 'online' : 'offline'}}</b-badge>
      </b-card>

      <b-card :class="(amountOfKnowledgeMaps !== 0) ? 'selectable' : 'customDisabled'" @click="$router.push({ name: 'ontodiaRDFStore' })">
        <div slot="header">
          <h4 class="float-left">Fachlandkarte bearbeiten</h4>
          <h4 class="float-right"><font-awesome-icon icon="map"/></h4>
        </div>
        <b-card-text>
          Öffnen Sie hinterlegte Fachlandkarten in einem grafischen Editor (Ontodia-Darstellung), um sie zu analysieren und zu bearbeiten
        </b-card-text>
        <b-badge v-b-tooltip.hover title="Anzahl verfügbarer Fachlandkarten"><font-awesome-icon icon="hashtag"/> {{amountOfKnowledgeMaps}}</b-badge>
      </b-card>

      <b-card class="selectable" @click="$router.push({ name: 'ontodiaLocalFile' })">
        <div slot="header">
          <h4 class="float-left">Lokale Fachlandkarte darstellen</h4>
          <h4 class="float-right"><font-awesome-icon icon="map-marked-alt"/></h4>
        </div>
        <b-card-text>
          Öffnen Sie lokale Fachlandkarten (Dateien von Ihrem PC) in der Ontodia-Darstellung, um diese zu analysieren und ggf. als Fachlandkarten online abspeichern
        </b-card-text>
      </b-card>

      <b-card class="selectable" @click="$router.push({ name: '3dgraph' })" border-variant="warning"
        header-bg-variant="warning"
        header-text-variant="black">
        <div slot="header">
          <h4 class="float-left">Karte in 3D darstellen</h4>
          <h4 class="float-right"><font-awesome-icon icon="vr-cardboard"/></h4>
        </div>
        <b-card-text>
          <p>Öffnen Sie Individual-Modelle, Cluster-Karten und Fachlandkarten (hinterlegt oder als lokale Datei von Ihrem PC) in einer 3D-Darstellung, um diese zu erkunden, zu analysieren und ggf. in der Lehre einzusetzen</p>
          <h3 style="color: red;">Vorschau</h3>
        </b-card-text>
      </b-card>

      <b-card class="selectable" @click="$router.push({ name: 'sigma' })" border-variant="warning" header-bg-variant="warning" header-text-variant="black">
        <div slot="header">
          <h4 class="float-left">Karten darstellen & vergleichen</h4>
          <h4 class="float-right"><font-awesome-icon icon="shapes"/></h4>
        </div>
        <b-card-text>
          <p>Öffnen Sie Individual-Modelle, Cluster-Karten und Fachlandkarten (hinterlegt oder als lokale Datei von Ihrem PC) in der Sigma-Darstellung, um diese zu erkunden, zu analysieren und ggf. zu einer zweiten Karte zu vergleichen</p>
          <h3 style="color: red;">Vorschau</h3>
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import kmAPI from '@/api/knowledgeMap'

export default {
  name: 'home',
  data () {
    return {
      amountOfKnowledgeMaps: 0
    }
  },
  async created () {
    this.amountOfKnowledgeMaps = (await kmAPI.getAvailableMaps()).length
  }
}
</script>

<style scoped>
  .card-header > div > h4 {
    margin-top: 0.4rem  ;
    margin-bottom: 0;
  }
  .card {
    margin-bottom: 1rem !important;
    min-width: 22rem;
  }
  .card.selectable:hover {
    filter: brightness(98%);
    cursor: pointer !important;
  }
  .card-text {
    text-align: left;
  }
  .badge {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
  .customDisabled > .card-header, .customDisabled > .card-body {
    opacity: 0.5;
  }
</style>
